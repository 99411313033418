import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./NavBar.module.css";

function Navbar() {
  return (
    <nav className="navbar navbar-expand">
      <Link to="/" className="navbar-brand mb-0 h1">
        💻 John Monnett
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav mx-auto">
          <li classNames="nav-item active">
            <Link to="/" className="nav-link">
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/skills" className="nav-link">
              {" "}
              Skills{" "}
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/projects" className="nav-link">
              {" "}
              Projects{" "}
            </Link>
          </li>
        </ul>
        <div classname="flexbox">
        <ul className="navbar-nav sm-icons">
          <li className="nav-item">
            <a className="nav-link" href="https://github.com/jcmonnett">
              <img
                src="./Icons/github.svg"
                alt="Bootstrap"
                width="16"
                height="16"
              />
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="https://www.linkedin.com/in/john-monnett/">
              <img
                src="./Icons/linkedin.svg"
                alt="Bootstrap"
                width="16"
                height="16"
              />
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/">
              <img
                src="./Icons/twitter.svg"
                alt="Bootstrap"
                width="16"
                height="16"
              />
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/">
              <img
                src="./Icons/journal-richtext.svg"
                alt="Bootstrap"
                width="16"
                height="16"
              />
            </a>
          </li>
        </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
