import "./App.css";
import { Route, Routes } from "react-router-dom";
import React, { useEffect } from 'react';

import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import SkillsPage from "./pages/skills.js";
import AboutPage from "./pages/about.js";
import ProjectsPage from "./pages/projects.js";
import NavBar from "./components/NavBar/NavBar.js";

function App() {
  useEffect(() => {
    document.title = "John Monnett";
  }, []);
  return (
    <div>
      <Routes>
        <Route path="/" element={<><NavBar /><AboutPage/></>} />
        <Route path="/skills" element={<><NavBar /><SkillsPage/></>} />
        <Route path="/projects" element={<><NavBar /><ProjectsPage/></>} />
      </Routes>
    </div>
  );
}

export default App;
