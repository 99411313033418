import React, { useEffect } from 'react';
import "./skills.css";

function SkillsPage() {
  useEffect(() => {
    document.title = "Skills - John Monnett";
  }, []);
  return (
    <div className="SkillsPage">
      <header className="App-header">
      <h1>What do I know?</h1>
      <ul>
          <img src="./Icons/skillicons/icon-html-5.svg" alt="HTML5" className="Skill"></img>
          <img src="./Icons/skillicons/icon-css3.svg" alt="CSS3" className="Skill"></img>
          <img src="./Icons/skillicons/icon-javascript.svg" alt="Javascript" className="Skill"></img>
          <img src="./Icons/skillicons/icon-node-js.svg" alt="NodeJS" className="Skill"></img>
          <img src="./Icons/skillicons/icon-jquery.png" alt="JQuery" className="Skill"></img>
      </ul>
      <ul>
          <img src="./Icons/skillicons/icon-react.svg" alt="React" className="Skill"></img>
          <img src="./Icons/skillicons/icon-typescript.svg" alt="typescript" className="Skill"></img>
          <img src="./Icons/skillicons/icon-github.svg" alt="Github" className="Skill"></img>
          <img src="./Icons/skillicons/icon-git.svg" alt="Git" className="Skill"></img>
          <img src="./Icons/skillicons/icon-java.svg" alt="Java" className="Skill"></img>
      </ul>
      <ul>
          <img src="./Icons/skillicons/icon-python.svg" alt="Python" className="Skill"></img>
          <img src="./Icons/skillicons/icon-postgresql.svg" alt="Postgresql" className="Skill"></img>
          <img src="./Icons/skillicons/icon-mongodb.png" alt="MongoDB" className="Skill"></img>
          <img src="./Icons/skillicons/icon-visual-studio-code.svg" alt="VSCode" className="Skill"></img>
          <img src="./Icons/skillicons/icon-rest-api.png" alt="RestAPI" className="Skill"></img>
      </ul>
      </header>
      
      
      
  
      </div>
  );
}

export default SkillsPage;
