import React, { useEffect } from 'react';
import "./about.css";

function AboutPage() {
  useEffect(() => {
    document.title = "About - John Monnett";
  }, []);
  return (
    <div className="AboutPage">
      <header className="App-header">
        <h1> Hi, My name is John Monnett. 👨🏻‍💻</h1>
        <p>I am a Software Developer. 🛠</p>
        <div>
         <p>
          Highly energetic, self-driven, and exceptionally motivated, I possess outstanding technical, communication, and interpersonal skills. I am currently seeking to transition into a full-time software developer role within a company that offers substantial growth opportunities in this dynamic field. With a proven track record in time management and problem-solving, I have amassed extensive experience across a diverse range of technologies, exhibiting remarkable effectiveness in collaborative team environments while also demonstrating the ability to work autonomously.
      
        </p>
          <p> 
          My insatiable enthusiasm for acquiring new skills and leveraging them to develop solutions that surpass business requirements is a driving force in my professional pursuits. Fearlessly embracing challenges, I am committed to exerting utmost effort and intelligence to achieve successful outcomes. I firmly believe that my presence within any software development organization would be an invaluable asset. I am a dependable individual upon whom you can rely.
          
          </p>
          <p> 
          From a young age, I harbored a deep passion for technology, as evidenced by my endeavors in building and familiarizing myself with computers. This ardor for technology further flourished as I ventured into the realm of IT, where I applied my existing knowledge to solve various client issues independently. Resourceful utilization of online platforms complemented my problem-solving aptitude, enabling me to enhance my skills.
         
          </p>
          <p>
          As my competence in IT flourished, I realized my unwavering aspiration to immerse myself in software development. I yearned to be the individual crafting the captivating and enigmatic code that leaves people, including myself, in awe. Through relentless dedication to learning diverse programming languages and experimenting with various tools, I discovered a natural affinity for the meticulous work and problem-solving intricacies inherent in personal projects. This magnetic pull drew me deeper into the realm of software development, compelling me to unravel the secrets of making things function seamlessly.
          </p>
        
        </div>
      </header>
    </div>
  );
}

export default AboutPage;
