import React, { useEffect } from 'react';

import "./projects.css";

const ProjectsPage = () => {
  const projects = [
      {name: 'Project 1', url: 'https://project1.com', img: './Icons/web-development.png'},
      {name: 'Project 2', url: 'https://project2.com', img: './Icons/web-development.png'},
      {name: 'Project 3', url: 'https://project3.com', img: './Icons/web-development.png'},
      {name: 'Project 4', url: 'https://project4.com', img: './Icons/web-development.png'},
      {name: 'Project 5', url: 'https://project5.com', img: './Icons/web-development.png'},
  ];

  useEffect(() => {
    document.title = "Projects - John Monnett";
  }, []);

  return (
    <div className="App-header">
    <div className="projects-container">
            <h2>My Projects</h2>
            {projects.map((project, index) => (
                <div key={index} className="project">
                    <a href={project.url} target="_blank" rel="noopener noreferrer">
                        <img src={project.img} alt={project.name} />
                        <p>{project.name}</p>
                    </a>
                </div>
            ))}
        </div>
        </div>
    );
};


export default ProjectsPage;
